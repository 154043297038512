import type { ComponentType } from "react"
import { motion } from "framer-motion"

export function withDroplet(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                as={motion.div}
                animate={{ scale: [1, 2], opacity: [1, 0] }}
                transition={{
                    duration: 0.8,
                    repeatDelay: 0.7,
                    repeatType: "loop",
                    repeat: Infinity,
                    ease: "easeOut",
                }}
            />
        )
    }
}
